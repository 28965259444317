<template>
  <div>
    <Panel title="Bestanden" :is-collapsible="false">
      <span slot="toolbar-buttons">
        <button type="button" class="btn btn-info btn-xs" @click="openFileModal">
          <i class="fa fa-plus" /> Bestand toevoegen
        </button>
      </span>
      <DataTable
        :loading="loading"
        :headers="headers"
        v-bind="files"
        class="tw--m-5 tw-shadow-none"
        @changePage="loadContactFiles"
      >
        <template #item-actions="{ item }">
          <div class="tw-flex">
            <button
              type="button"
              title="Bestand downloaden"
              class="action tw-bg-info"
              @click="downloadContactFile(item)"
            >
              <i class="far fa-download"/>
            </button>
            <button
              type="button"
              title="Verwijderen"
              class="action tw-bg-error"
              @click="removeContactFile(item)"
            >
              <i class="far fa-trash-alt"/>
            </button>
          </div>
        </template>
        <template #item-upload_date="{ item }">
          {{ item.date_created | date-day}}
        </template>
      </DataTable>
    </Panel>
    <ContactFileUploadModal
      ref="fileUploadModal"
      :contact="contact"
      @refreshFiles="loadContactFiles"
    />
  </div>
</template>

<script>
import Panel from '@/components/iam/Panel'
import DataTable from '@/components/DataTable'
import ContactFileUploadModal from '@/components/contacts/ContactFileUploadModal'

import { getContactFiles, deleteContactFile } from '@/services/contacts'
import { questionModal, startLoadingModal, successModal, errorModal } from '@/modalMessages'
import { mapState } from 'vuex'

export default {
  name: 'ContactFiles',
  components: { DataTable, Panel, ContactFileUploadModal },
  data () {
    return {
      loading: false,
      // The object files includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      files: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    ...mapState('contacts', ['contact']),

    headers () {
      return [
        { value: 'name', text: 'Name' },
        { value: 'type_display', text: 'Type' },
        { value: 'upload_date', text: 'Geüpload op' },
        { value: 'actions', text: 'Actie' }
      ]
    }
  },
  created () {
    this.loadContactFiles()
  },
  methods: {
    openFileModal () {
      this.$refs.fileUploadModal.show()
    },
    downloadContactFile (file) {
      const win = window.open(file.url, '_blank')
      if (!win) errorModal('Sta pop-ups toe om het bestand te zien.')
    },
    async loadContactFiles (payload = { contactId: this.contact.id }) {
      try {
        this.loading = true
        const response = await getContactFiles(payload)
        this.files = response.data
        this.loading = false
        return response
      } catch (error) {
        errorModal('Fout bij het laden van bestanden, probeer het opnieuw.')
      }
    },
    async removeContactFile (file) {
      try {
        const result = await questionModal('Weet je zeker dat je dit bestand wilt verwijderen?')
        if (!result.value) return

        startLoadingModal('Bestand verwijderen...')
        const response = await deleteContactFile(this.contact.id, file.id)
        successModal(`Het document '${file.name}' is verwijderd`)
        this.loadContactFiles()
        return response
      } catch (error) {
        errorModal('Fout bij het verwijderen van het bestand, probeer het opnieuw.')
      }
    }
  }
}
</script>
