<template>
  <Modal ref="modal" title="Bestanden uploaden">
    <FileDropzone :url="fileUploadUrl" @file-uploaded="refreshContactFiles" />
    <div v-if="messages.length > 0">
      <ul>
        <li :key="index" v-for="(message, index) in messages">{{ message }}</li>
      </ul>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import FileDropzone from '@/components/iam/FileDropzone'

export default {
  name: 'ContactFileUploadModal',
  components: { FileDropzone, Modal },
  props: ['contact'],
  data () {
    return {
      messages: []
    }
  },
  computed: {
    fileUploadUrl () {
      return `/api/contacts/${this.contact.id}/files`
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
      this.messages = []
    },
    refreshContactFiles (fileName) {
      this.messages.unshift(`Bestand '${fileName} is toegevoegd aan dit dossier`)
      this.$emit('refreshFiles')
    }
  }
}
</script>
